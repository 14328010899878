<template>
	<div id="main">
		<div v-if="isMyndConfigReady && isGapiReady" id="app">
			<modal-overlay></modal-overlay>
			<router-view />
			<toast-display :displayTime="3000" :maxDisplayed="3" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, onBeforeUnmount } from 'vue'
import { useConfigStore } from '@/modules/mynd-config'
import { useGoogleSignIn } from '@/modules/auth'
import { useFlagsStore } from '@/modules/flags/store'
import { useFeatureFlags } from '@/modules/flags'
import { getConfig } from '@/modules/api/hook'
import * as E from 'fp-ts/lib/Either'
import ModalOverlay from '@/components/Modal/Overlay'
import ToastDisplay from '@/components/Toast'
import LogRocket from 'logrocket'

export default defineComponent({
	name: 'App',
	components: {
		ModalOverlay,
		ToastDisplay,
	},
	setup() {
		const configStore = useConfigStore()
		const config = computed(() => configStore.getters['getConfig'])
		const isMyndConfigReady = computed(() => !!configStore.getters['getConfig'].googleClientId)

		getConfig()().then((configResponse) => {
			E.map((config: any) => {
				configStore.commit('setConfig', config)
				if (config.environment === 'sandbox' || config.environment === 'production') {
					LogRocket.init('ta8rj2/myndmap')
				}
			})(configResponse)
		})
		const { isGapiReady } = useGoogleSignIn()
		const flagStore = useFlagsStore()
		const { startFeatureFlagPolling, stopFeatureFlagPolling, isActiveFeature } =
			useFeatureFlags(flagStore)

		window.addEventListener('contextmenu', (event) => {
			event.preventDefault()
		})

		watch(isMyndConfigReady, (isMyndConfigReady) => {
			if (isMyndConfigReady) {
				// TODO - update this with the normal way tog et feature flags
				isActiveFeature('ms19118DisableAdditionalInformation')
				startFeatureFlagPolling(config)
			}
		})

		onBeforeUnmount(() => {
			stopFeatureFlagPolling()
		})

		return {
			isMyndConfigReady,
			isGapiReady,
		}
	},
})
</script>

<style lang="scss">
@import '@/styles/colors.scss';
@import '@/styles/reset.scss';
@import '@/styles/fonts.scss';
@import '@/styles/buttons.scss';
@import '@/styles/scrollbar.scss';

#app {
	font-family: Effra;
	height: 100vh;
}
</style>
